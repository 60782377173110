import React from 'react'

const EditPen = ({size}) => {
    return (
        <svg width={size?size:"18"} height={size?size:"18"} viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path opacity="0.7" d="M16.9927 15.9531H11.2984C10.7429 15.9531 10.291 16.4121 10.291 16.9765C10.291 17.5419 10.7429 17.9999 11.2984 17.9999H16.9927C17.5483 17.9999 18.0001 17.5419 18.0001 16.9765C18.0001 16.4121 17.5483 15.9531 16.9927 15.9531Z" fill="#606586" />
            <path d="M7.30902 3.90385L12.7049 8.26394C12.835 8.36821 12.8573 8.55956 12.7557 8.69286L6.35874 17.0282C5.95662 17.5431 5.36402 17.8344 4.72908 17.8452L1.23696 17.8882C1.05071 17.8903 0.887748 17.7613 0.845419 17.5764L0.0517547 14.1258C-0.0858138 13.4915 0.0517547 12.8358 0.453878 12.3306L6.88256 3.95545C6.98627 3.82108 7.1778 3.79743 7.30902 3.90385Z" fill="#2D3462" />
            <path opacity="0.7" d="M15.1203 5.66544L14.0801 6.96401C13.9753 7.09623 13.7869 7.11773 13.6568 7.01238C12.3922 5.98901 9.15405 3.36285 8.25563 2.63509C8.12441 2.52759 8.10642 2.33625 8.21224 2.20295L9.21543 0.957057C10.1255 -0.214663 11.7128 -0.322161 12.9933 0.699064L14.4642 1.87078C15.0674 2.34377 15.4695 2.96726 15.6071 3.62299C15.7658 4.3443 15.5965 5.0527 15.1203 5.66544Z" fill="#606586" />
        </svg>

    )
}

export default EditPen