import React from 'react'
import { Link } from 'react-router-dom'

const Testing = () => {
    return (
        <div className='p-4 ' style={{ width: '100%', minHeight: '100vh', backgroundColor: 'grey' }}>


            <div className='p-4 border mt-4'>
                <Link to='/Letter_of__appointment' className='text-white side-nav'>
                    <li className='ms-2 nav-link'><i class="fa-solid fa-arrows-left-right me-3"></i>Letter of Appointment</li>
                </Link>

            </div>

            <div className='p-4 border mt-4'>
                <Link to='/Sweet_aleart' className='text-white side-nav'>
                    <li className='ms-2 nav-link'><i class="fa-solid fa-arrows-left-right me-3"></i>Sweet Aleart</li>
                </Link>

            </div>

            <div className='p-4 border mt-4'>
                <Link to='/Letter_of_appointment' className='text-white side-nav'>
                    <li className='ms-2 nav-link'><i class="fa-solid fa-arrows-left-right me-3"></i>Letter_of_appointment</li>
                </Link>

            </div>

            <div className='p-4 border mt-4'>
                <Link to='/Final_status_comment' className='text-white side-nav'>
                    <li className='ms-2 nav-link'><i class="fa-solid fa-arrows-left-right me-3"></i>Final_status_comment</li>
                </Link>

            </div>
            <div className='p-4 border mt-4'>
                <Link to='/Can' className='text-white side-nav'>
                    <li className='ms-2 nav-link'><i class="fa-solid fa-arrows-left-right me-3"></i>Canditate</li>
                </Link>

            </div>
            <div className='p-4 border mt-4'>
                <Link to='/Forgot_Otp_Verification' className='text-white side-nav'>
                    <li className='ms-2 nav-link'><i class="fa-solid fa-arrows-left-right me-3"></i>Forgot Otp Verification</li>
                </Link>

            </div>
            <div className='p-4 border mt-4'>
                <Link to='/Login__' className='text-white side-nav'>
                    <li className='ms-2 nav-link'><i class="fa-solid fa-arrows-left-right me-3"></i>Login__</li>
                </Link>

            </div>

            <div className='p-4 border mt-4'>
                <Link to='/Applay_List' className='text-white side-nav'>
                    <li className='ms-2 nav-link'><i class="fa-solid fa-arrows-left-right me-3"></i>Applay List</li>
                </Link>

            </div>
            <div className='p-4 border mt-4'>
                <Link to='/Otp_verifivation' className='text-white side-nav'>
                    <li className='ms-2 nav-link'><i class="fa-solid fa-arrows-left-right me-3"></i>OTP Verifivation</li>
                </Link>

            </div>

            <div className='p-4 border mt-4'>
                <Link to='/Forgot_password' className='text-white side-nav'>
                    <li className='ms-2 nav-link'><i class="fa-solid fa-arrows-left-right me-3"></i>Forgot Password</li>
                </Link>

            </div>

            <div className='p-4 border mt-4'>
                <Link to='/Set_password' className='text-white side-nav'>
                    <li className='ms-2 nav-link'><i class="fa-solid fa-arrows-left-right me-3"></i>Set Password</li>
                </Link>

            </div>

            <div className='p-4 border mt-4'>
                <Link to='/Signup_' className='text-white side-nav'>
                    <li className='ms-2 nav-link'><i class="fa-solid fa-arrows-left-right me-3"></i>Signup Page</li>
                </Link>

            </div>
            <div className='p-4 border mt-4'>
                <Link to='/Login__' className='text-white side-nav'>
                    <li className='ms-2 nav-link'><i class="fa-solid fa-arrows-left-right me-3"></i>Login Page</li>
                </Link>

            </div>

            <div className='p-4 border mt-4'>
                <Link to='/Employee_Profile' className='text-white side-nav'>
                    <li className='ms-2 nav-link'><i class="fa-solid fa-arrows-left-right me-3"></i>Employee Profile</li>
                </Link>

            </div>
            {/* <div className='p-4 border '>
                <Link to='/Attachements' className='text-white side-nav'>
                    <li className='ms-2 nav-link'><i class="fa-solid fa-arrows-left-right me-3"></i>Attachements</li>
                </Link>

            </div> */}

            {/* <div className='p-4 border mt-4'>
                <Link to='/DocumentUpload' className='text-white side-nav'>
                    <li className='ms-2 nav-link'><i class="fa-solid fa-arrows-left-right me-3"></i>Document Upload</li>
                </Link>

            </div> */}
            {/* 
            <div className='p-4 border mt-4'>
                <Link to='/Activites-' className='text-white side-nav'>
                    <li className='ms-2 nav-link'><i class="fa-solid fa-arrows-left-right me-3"></i>Activites</li>
                </Link>

            </div> */}

            <div className='p-4 border mt-4'>
                <Link to='/Basic' className='text-white side-nav'>
                    <li className='ms-2 nav-link'><i class="fa-solid fa-arrows-left-right me-3"></i> Basic</li>
                </Link>

            </div>

            <div className='p-4 border mt-4'>
                <Link to='/Performance_metrics' className='text-white side-nav'>
                    <li className='ms-2 nav-link'><i class="fa-solid fa-arrows-left-right me-3"></i>Performance Metrics</li>
                </Link>

            </div>




            <div className='p-4 border mt-4'>
                <Link to='/Sample_acti' className='text-white side-nav'>
                    <li className='ms-2 nav-link'><i class="fa-solid fa-arrows-left-right me-3"></i>Sample Activities</li>
                </Link>

            </div>

            <div className='p-4 border mt-4'>
                <Link to='/Applay' className='text-white side-nav'>
                    <li className='ms-2 nav-link'><i class="fa-solid fa-arrows-left-right me-3"></i>Applay List</li>
                </Link>

            </div>
            <div className='p-4 border mt-4'>
                <Link to='/Employee_' className='text-white side-nav'>
                    <li className='ms-2 nav-link'><i class="fa-solid fa-arrows-left-right me-3"></i>Employee</li>
                </Link>

            </div>

            <div className='p-4 border mt-4'>
                <Link to='/Canditate_Registration_Form' className='text-white side-nav'>
                    <li className='ms-2 nav-link'><i class="fa-solid fa-arrows-left-right me-3"></i>Canditate Reg form</li>
                </Link>

            </div>

            <div className='p-4 border mt-4'>
                <Link to='/Login_' className='text-white side-nav'>
                    <li className='ms-2 nav-link'><i class="fa-solid fa-arrows-left-right me-3"></i>Login</li>
                </Link>

            </div>

            <div className='p-4 border mt-4'>

                <li className='text-white ms-2' data-bs-toggle="collapse" href="#multiCollapseExample133" aria-expanded="false" aria-controls="multiCollapseExample1"> <i class="fa-solid fa-bars-progress me-1"></i> Leave Management <span style={{ position: 'relative', left: "10px" }}><i class="fa-solid fa-caret-down text-white"></i></span></li>
                <div class="collapse multi-collapse" id="multiCollapseExample133">
                    <div class=" ">
                        <ul className='nav flex-column ms-2' >
                            <Link to='/Leaveapplyform' className='text-white side-nav' >
                                <li  >Leave application</li>
                            </Link>

                            <Link to='/Holidays' className='text-white side-nav' >
                                <li>Holidays</li>
                            </Link>


                        </ul>
                    </div>
                </div>

            </div>






            <div className='p-4 border mt-4'>
                <li className='text-white ms-2' style={{ fontSize: '12px' }} data-bs-toggle="collapse" href="#multiCollapseExample12" aria-expanded="false" aria-controls="multiCollapseExample1"> <i class="fa-solid fa-bars-progress me-3"></i>Attendance Management <span style={{ position: 'relative', left: "20px" }}><i class="fa-solid fa-caret-down text-white"></i></span></li>
                <div class="collapse multi-collapse" id="multiCollapseExample12">
                    <div class=" ">
                        <ul className='nav flex-column ms-2' >
                            <Link to='/Employeeattendance' className='text-white side-nav' >
                                <li>Attendance</li>
                            </Link>
                            {/* <Link className='text-white side-nav' >
                                            <li  >Leave Management</li>
                                        </Link>
                                        <Link className='text-white side-nav' >
                                            <li  >Leave Management</li>
                                        </Link>

                                        <Link className='text-white side-nav' >
                                            <li>Attendance Management</li>
                                        </Link>

                                        <Link className='text-white side-nav' >
                                            <li>Payroll Management</li>
                                        </Link> */}
                        </ul>
                    </div>
                </div>
            </div>
            {/* <Link className='text-white side-nav' >

                                <li className='ms-2'><i class="fa-solid fa-list-check me-3"></i> Task Manager</li>
                            </Link> */}
            <div className='p-4 border mt-4'>

                <Link className='text-white side-nav' >
                    <li className='ms-2'><i class="fa-solid fa-door-open me-3"></i> Open Postion</li>
                </Link>


            </div>


            <div className='p-4 border mt-4'>

                <Link className='text-white side-nav'>
                    <li className='ms-2'><i class="fa-solid fa-chart-column me-3"></i> Employee Tracking</li>
                </Link>
            </div>

            <div className='p-4 border mt-4'>



                <Link className='text-white side-nav'>
                    <li className='ms-2'><i class="fa-regular fa-calendar-plus me-3"></i> UpComming Holidays</li>
                </Link>

            </div>

            <div className='p-4 border mt-4'>

                <Link to='/Employeeallform/:id' className='text-white side-nav'>
                    <li className='ms-2'><i class="fa-regular fa-calendar-plus me-3"></i> Employeeallform</li>
                </Link>
            </div>

            <div className='p-4 border mt-4'>


                <Link to='/Employeeseparation' className='text-white side-nav'>
                    <li className='ms-2'><i class="fa-regular fa-calendar-plus me-3"></i> Employee Separation</li>
                </Link>
            </div>

            <div className='p-4 border mt-4'>

                <Link to='/offeraccept/:id' className='text-white side-nav'>
                    <li className='ms-2'><i class="fa-regular fa-calendar-plus me-3"></i> Offer Accepted</li>
                </Link>
            </div>
            <div className='p-4 border mt-4'>
                <Link to='/BgverificationForm/:id' className='text-white side-nav'>
                    <li className='ms-2'><i class="fa-regular fa-calendar-plus me-3"></i>Bg Verification Form</li>
                </Link>
            </div>

            <div className='p-4 border mt-4'>
                <Link to='/Leaveapplyform' className='text-white side-nav'>
                    <li className='ms-2'><i class="fa-regular fa-calendar-plus me-3"></i>Leave Apply Form</li>
                </Link>
            </div>

            <div className='p-4 border mt-4'>
                <Link to='/Emp_leave_grant_form' className='text-white side-nav'>
                    <li className='ms-2'><i class="fa-regular fa-calendar-plus me-3"></i>Leave Grant Form</li>
                </Link>
            </div>
            <div className='p-4 border mt-4'>
                <Link to='/Emp_leave_request' className='text-white side-nav'>
                    <li className='ms-2'><i class="fa-regular fa-calendar-plus me-3"></i>Leave Request</li>
                </Link>
            </div>
            {/* <Link to='/Mass_Mail' className='text-white side-nav'>
                                <li className='ms-2'><i class="fa-regular fa-calendar-plus me-3"></i>Mass Mail</li>
                            </Link> */}
            <div className='p-4 border mt-4'>
                <Link to='/Doc' className='text-white side-nav'>
                    <li className='ms-2'><i class="fa-regular fa-calendar-plus me-3"></i>Doc Verification form</li>
                </Link>
            </div>
            <div className='p-4 border mt-4'>
                <Link to='/Testing' className='text-white side-nav'>
                    <li className='ms-2'><i class="fa-regular fa-calendar-plus me-3"></i>Testing</li>
                </Link>

            </div>



        </div>
    )
}

export default Testing