import React from 'react'

const ExportIcon = ({ size }) => {
    return (
        <svg width={size ? size : "33"} height={size ? size : "33"} viewBox="0 0 33 33" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M16.165 3.50963L16.165 22.542" stroke="#EC6917" stroke-width="2.37094" stroke-linecap="round" stroke-linejoin="round" />
            <path d="M8.68062 12.457H7.17352C3.88633 12.457 1.22266 15.0635 1.22266 18.2817L1.22266 26.0014C1.22266 29.2101 3.87987 31.8103 7.15898 31.8103L25.1537 31.8103C28.4409 31.8103 31.1062 29.2022 31.1062 25.9856L31.1062 18.2643C31.1062 15.0572 28.4474 12.457 25.1699 12.457H23.6482" stroke="#EC6917" stroke-width="2.37094" stroke-linecap="round" stroke-linejoin="round" />
            <path d="M21.2969 6.44141L16.5866 1.81332L11.8763 6.44141" stroke="#EC6917" stroke-width="2.37094" stroke-linecap="round" stroke-linejoin="round" />
        </svg>  
    )
}

export default ExportIcon