import React from 'react'

const ThreeDot = ({ size }) => {
    return (
        <svg width={size ? size : "9"} height={size ? size * 4 : "36"} viewBox="0 0 9 36" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path fill-rule="evenodd" clip-rule="evenodd" d="M8.55481 4.80865C8.55481 7.05019 6.73769 8.86731 4.49615 8.86731C2.25462 8.86731 0.4375 7.05019 0.4375 4.80865C0.4375 2.56712 2.25462 0.75 4.49615 0.75C6.73769 0.75 8.55481 2.56712 8.55481 4.80865ZM8.55481 18.3375C8.55481 20.579 6.73769 22.3961 4.49615 22.3961C2.25462 22.3961 0.4375 20.579 0.4375 18.3375C0.4375 16.0959 2.25462 14.2788 4.49615 14.2788C6.73769 14.2788 8.55481 16.0959 8.55481 18.3375ZM4.49615 35.9249C6.73769 35.9249 8.55481 34.1078 8.55481 31.8663C8.55481 29.6248 6.73769 27.8076 4.49615 27.8076C2.25462 27.8076 0.4375 29.6248 0.4375 31.8663C0.4375 34.1078 2.25462 35.9249 4.49615 35.9249Z" fill="#2D3462" />
        </svg>

    )
}

export default ThreeDot