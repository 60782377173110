import React from 'react'

const ImportIcon = ({size}) => {
    return (
        
         <svg width={size ? size : "33"} height={size ? size - 2 : "31"} viewBox="0 0 33 31" fill="none" xmlns="http://www.w3.org/2000/svg">
         <path d="M16.165 21.1154L16.165 2.08301" stroke="#68EC17" stroke-width="2.37094" stroke-linecap="round" stroke-linejoin="round" />
         <path d="M23.6475 9.56396H25.1546C28.4418 9.56396 31.1055 12.1704 31.1055 15.3886L31.1055 23.1084C31.1055 26.3171 28.4483 28.9172 25.1691 28.9172L7.17441 28.9172C3.88722 28.9172 1.22193 26.3092 1.22193 23.0926L1.22193 15.3712C1.22193 12.1641 3.88076 9.56396 7.15826 9.56396H8.67989" stroke="#68EC17" stroke-width="2.37094" stroke-linecap="round" stroke-linejoin="round" />
         <path d="M21.2969 18.1836L16.5866 22.8117L11.8763 18.1836" stroke="#68EC17" stroke-width="2.37094" stroke-linecap="round" stroke-linejoin="round" />
     </svg>
    )
}

export default ImportIcon