import React from 'react'

const ViewBtn = () => {
    return (
        <svg width="20" height="16" viewBox="0 0 20 16" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path fill-rule="evenodd" clip-rule="evenodd" d="M15.7366 2.04606C17.4439 3.36388 18.8976 5.29455 19.9415 7.70909C20.0195 7.89236 20.0195 8.10667 19.9415 8.28121C17.8537 13.1103 14.1366 16 10 16H9.99024C5.86341 16 2.14634 13.1103 0.0585366 8.28121C-0.0195122 8.10667 -0.0195122 7.89236 0.0585366 7.70909C2.14634 2.87903 5.86341 0 9.99024 0H10C12.0683 0 14.0293 0.717576 15.7366 2.04606ZM6.09753 8C6.09753 10.1333 7.84388 11.8691 9.99997 11.8691C12.1463 11.8691 13.8927 10.1333 13.8927 8C13.8927 5.85697 12.1463 4.12122 9.99997 4.12122C7.84388 4.12122 6.09753 5.85697 6.09753 8Z" fill="#2D3462" />
            <path d="M12.4308 7.997C12.4308 9.32548 11.3381 10.4115 10.0015 10.4115C8.65518 10.4115 7.5625 9.32548 7.5625 7.997C7.5625 7.83215 7.58201 7.67797 7.61128 7.52281H7.66006C8.74299 7.52281 9.62104 6.66948 9.66006 5.60184C9.76738 5.58342 9.88445 5.57275 10.0015 5.57275C11.3381 5.57275 12.4308 6.65881 12.4308 7.997Z" fill="#8A8EA5" />
        </svg>

    )
}

export default ViewBtn