import React from 'react'

const DustbinIcon = ({ size }) => {
    return (
        <svg width={size?size-1:"16"} height={size?size:"16"} viewBox="0 0 15 16" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M13.922 5.94925C13.922 6.00327 13.4986 11.3584 13.2568 13.6122C13.1053 14.9953 12.2137 15.8342 10.8763 15.858C9.84867 15.881 8.84269 15.889 7.85294 15.889C6.80215 15.889 5.77454 15.881 4.77707 15.858C3.48444 15.827 2.59204 14.9714 2.44833 13.6122C2.19954 11.3505 1.78386 6.00327 1.77614 5.94925C1.76841 5.78639 1.82095 5.63148 1.92757 5.50596C2.03265 5.38998 2.18409 5.32007 2.34325 5.32007H13.3626C13.521 5.32007 13.6647 5.38998 13.7783 5.50596C13.8842 5.63148 13.9375 5.78639 13.922 5.94925Z" fill="#606586" />
            <path d="M14.9992 3.1594C14.9992 2.83289 14.7419 2.57709 14.4329 2.57709H12.1165C11.6452 2.57709 11.2357 2.24184 11.1306 1.76917L11.0008 1.19004C10.8192 0.490155 10.1926 0 9.48953 0H6.20968C5.49885 0 4.87842 0.490155 4.6899 1.22817L4.5686 1.76996C4.46274 2.24184 4.05324 2.57709 3.58271 2.57709H1.26634C0.956508 2.57709 0.699219 2.83289 0.699219 3.1594V3.46127C0.699219 3.77983 0.956508 4.04358 1.26634 4.04358H14.4329C14.7419 4.04358 14.9992 3.77983 14.9992 3.46127V3.1594Z" fill="#2D3462" />
        </svg>
    )
}

export default DustbinIcon