import React from 'react'

const ShiftTiming = () => {
    return (
        <div>
            <main className='tablebg table-responsive rounded ' >
                <table className=' '>

                </table>
            </main>

        </div>
    )
}

export default ShiftTiming